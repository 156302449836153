<template>
    <div class="main" :style="[bg]">
        <!-- 加载中 -->
        <div class="loading_box" v-if="loading">
            <div class="img">
                <img src="@/assets/images/img/duix.png" alt="">
            </div>
            <div class="warning"></div>
        </div>

        <div class="content" v-if="type==2">
            <div class="left">
                <div class="card">
                    <div class="card_tit">您可以对我说：</div>
                    <div class="card_list" v-for="(item,index) in list" :key="index">
                        <div class="dot"></div>
                        <span>{{item}}</span>
                    </div>
                </div>
                <div class="microphone">
                    <div class="img">
                        <img v-if="microphone" src="@/assets/images/icon/open_microphone.png" alt="" @click="closeMic">
                        <img v-else src="@/assets/images/icon/close_microphone.png" alt="" @click="openMic">
                    </div>
                    <!-- 音浪图 -->
                    <div class="charts">
                        <canvas class="sound_wave_canvas"></canvas>
                    </div>
                </div>
            </div>
            <div class="center"></div>
            <div class="right">
                <!-- 聊天框 -->
                <div class="chat_box" v-if="chat">
                    <div class="chat_item" :class="item.type==1?'user':'him'" v-for="(item,index) in chatList" :key="index">
                        {{item.value}}
                    </div>
                </div>

                <!-- 没有打开弹聊天框时, 数字人语音文字 -->
                <!-- <div class="audio_text">
                    嗨，欢迎来到DUIX，很开心和您相遇，我是您的数字人朋友， 您有任何关于硅基和我们平台的问题，都可以与我交流哦!
                </div> -->

                <div class="chat_bottom">
                    <div class="input_box" v-if="chat">
                        <input type="text" v-model="value" @keyup.enter="send" placeholder="请输入您想说的话···">
                        <span @click="send">发送</span>
                    </div>
                    <div class="btn">
                        <img v-if="chat" src="@/assets/images/icon/close_chat.png" alt="" @click="closeChat">
                        <img v-else src="@/assets/images/icon/chat.png" alt="" @click="openChat">
                    </div>
                </div>
            </div>
        </div>

        <point-out ref='dialogTip' dialogTitle="开始对话" dialogWidth='380px' type="session" :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true' @confirm="confirm">
            <div class="zc_dialog_tip_content">
                <div class="text">您即将开始与数字人对话。对话需要您打开麦克风权限，请在弹窗上单击允许</div>
            </div>
        </point-out>
        <point-out ref='errorTip' dialogTitle="提示" dialogWidth='380px' type="error" :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='false' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">{{text}}</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//提示
export default {
    components: {
        PointOut
    },
    data() {
        return {
            loading: false,
            type: 1,  //1加载  2主页面
            microphone: false,   //是否开启麦克风
            text: '没有检测到麦克风，请打开麦克风重试',
            list: [
                '什么是数字人？',
                '对于元宇宙你怎么看？',
                '你觉得你们的优势在哪里？',
                '怎么联系你们？',
                '数字人的应用场景有哪些？',
            ],
            chat: false,   //是否打开输入框
            value: '',  //聊天输入框
            chatList: [
                {
                    type: 2,
                    value: '嗨，欢迎来到DUIX，很开心和您相遇，我是您的数字人朋友， 您有任何关于硅基和我们平台的问题，都可以与我交流哦！',
                },
            ],
        };
    },
    created() {

    },
    computed: {
        bg() {
            if (this.type == 1) {
                return {
                    '--headbg': `url(${require('@/assets/images/background/session_bg1.png')})`,
                }
            } else {
                return {
                    '--headbg': `url(${require('@/assets/images/background/session_bg2.png')})`,
                }
            }
        },
    },
    mounted() {

        // this.type = 2
        // this.loading = false
        // this.$nextTick(() => {
        //     this.getAudio()
        // })

        this.loading = true
        setTimeout(() => {
            this.$refs.dialogTip.openDialogBtn()
        }, 2000)
    },
    methods: {
        // 发送
        send() {
            if (this.value == '') {
                return
            }
            this.chatList.push({
                value: this.value,
                type: 1,
            })
            this.value = ''
        },
        confirm() {
            this.type = 2
            this.loading = false
            this.$nextTick(() => {
                this.getAudio()
            })
        },
        //获取麦克风声音分贝
        getAudio() {
            var that = this
            //创建一个音频环境对像
            const audioContext = new (window.AudioContext || window.webkitAudioContext)()
            // console.log("音频环境对像---",audioContext);

            let source = null
            let recorder = null
            let volume = null
            let analyser = null
            let dataArray = null
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia({
                    audio: true
                }).then((stream) => {
                    this.microphone = true;

                    // //将声音输入这个对像
                    source = audioContext.createMediaStreamSource(stream); //创建一个音频源节点  createMediaStreamSource 创建媒体流源     createMediaElementSource  创建媒体元素源

                    //设置音量节点
                    volume = audioContext.createGain();
                    // console.log('音量节点---', volume);
                    source.connect(volume);

                    analyser = audioContext.createAnalyser() //创建一个分析器节点
                    // console.log("分析器节点", analyser);
                    analyser.fftSize = 256;


                    //创建数组，用于接收分析器节点的数据
                    var bufferLength = analyser.frequencyBinCount;
                    dataArray = new Uint8Array(bufferLength)
                    // console.log('分析器数据', dataArray);
                    source.connect(analyser)
                    //创建缓存，用来缓存声音
                    var bufferSize = 4096;

                    // // 创建声音的缓存节点，createJavaScriptNode方法的
                    // // 第二个和第三个参数指的是输入和输出都是双声道。
                    recorder = audioContext.createScriptProcessor(bufferSize, 1, 1);


                    // 将该分析对象与麦克风音频进行连接
                    source.connect(recorder)
                    // 此举无甚效果，仅仅是因为解决 Chrome 自身的 bug
                    recorder.connect(audioContext.destination)

                    // 录音过程的回调函数，基本上是将左右两声道的声音
                    // 分别放入缓存。
                    recorder.onaudioprocess = function (e) {
                        //让分析器节点分析出数据到数组中
                        analyser.getByteFrequencyData(dataArray)
                        // console.log(dataArray);
                        that.draw(dataArray)
                    }

                    // 将音量节点连上缓存节点，换言之，音量节点是输入
                    // 和输出的中间环节。
                    volume.connect(recorder);

                    // 将缓存节点连上输出的目的地，可以是扩音器，也可以
                    // 是音频文件。
                    recorder.connect(audioContext.destination);


                }).catch((error) => {
                    // console.log('失败')
                    this.text = '没有检测到麦克风，请打开麦克风重试'
                    this.$refs.errorTip.openDialogBtn()
                    this.microphone = false;
                })
            } else {
                this.text = '此浏览器暂不支持！'
                this.$refs.errorTip.openDialogBtn()
                this.microphone = false;
            }
        },

        draw(dataArray) {
            if (!this.microphone) {
                return
            }
            const cvs = document.querySelector('.sound_wave_canvas')
            const ctx = cvs.getContext('2d')
            //清空画布
            const {
                width,
                height
            } = cvs;
            ctx.clearRect(0, 0, width, height);

            const len = dataArray.length / 3.5;
            const barwidth = width / len / 2
            var linear = ctx.createLinearGradient(0, 0, 250, 0);
            linear.addColorStop(0.0, '#D921FF');
            linear.addColorStop(0.3, '#6647FF');
            linear.addColorStop(0.5, '#2D5AFF');
            linear.addColorStop(0.7, '#7D36FF');
            linear.addColorStop(1.0, '#B21FFF');
            ctx.fillStyle = linear;
            for (let i = 0; i < len; i++) {
                const data = dataArray[i]; //<256
                const barHeight = data / 400 * height
                const x1 = i * barwidth + width / 2;
                const x2 = width / 2 - (i + 1) * barwidth;
                const y = height / 2
                ctx.fillRect(x1, y, barwidth - 2, barHeight)
                ctx.fillRect(x1, y, barwidth - 2, -barHeight)
                ctx.fillRect(x2, y, barwidth - 2, barHeight)
                ctx.fillRect(x2, y, barwidth - 2, -barHeight)
            }
        },
        // 打开麦克风
        openMic() {
            this.microphone = true
        },
        // 关闭麦克风
        closeMic() {
            this.microphone = false
        },
        // 打开聊天框
        openChat() {
            this.chat = true
        },
        // 关闭聊天框
        closeChat() {
            this.chat = false
            this.value = ''
            this.chatList = []
        },
    },
};
</script>

<style scoped lang="scss">
.main {
    width: 100%;
    height: 100%;
    background-image: var(--headbg);
    background-repeat: no-repeat;
    background-size: cover;
    .loading_box {
        padding-top: 350px;
        margin: 0 auto;
        width: 260px;
        .img {
            width: 260px;
            height: 98px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .warning {
            margin: 40px auto 0;
            width: 150px;
            height: 8px;
            position: relative;
            background-color: #3ca5ff;
            border-radius: 5px;
            background-size: 30px 10px;
            background-image: linear-gradient(
                -45deg,
                #3ca5ff 36%,
                #2aceff 28%,
                #2aceff 67%,
                #3ca5ff 25%
            );
            -webkit-animation: warning-animation 500ms infinite linear;
            -moz-animation: warning-animation 500ms infinite linear;
            animation: warning-animation 500ms infinite linear;
        }
        @-webkit-keyframes warning-animation {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 30px 0;
            }
        }
        @-moz-keyframes warning-animation {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 30px 0;
            }
        }
        @keyframes warning-animation {
            0% {
                background-position: 0 0;
            }
            100% {
                background-position: 30px 0;
            }
        }
    }
    .content {
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 0 40px;
        .left {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .card {
                width: 362px;
                height: 300px;
                background: rgba(255, 255, 255, 0.6);
                border: 1px solid #ffffff;
                margin-bottom: 30px;
                position: relative;
                z-index: 10;
                padding: 30px;
                .card_tit {
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    line-height: 26px;
                }
                .card_list {
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    .dot {
                        width: 6px;
                        height: 6px;
                        background: #2e4bf2;
                        transform: rotate(45deg);
                    }
                    span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                        line-height: 20px;
                        padding-left: 8px;
                    }
                }
            }
            .card:before {
                border-style: solid;
                border-width: 0 0 20px 20px;
                border-color: transparent transparent transparent
                    rgba(255, 255, 255, 0.6);
                content: "";
                position: absolute;
                top: 20%;
                right: -16px;
                margin-top: -9px;
                display: block;
                width: 0px;
                height: 0px;
                z-index: 10;
            }
            .card:after {
                border-style: solid;
                border-width: 0 0 22px 22px;
                border-color: transparent transparent transparent #ffffff;
                content: "";
                position: absolute;
                top: 20%;
                right: -20px;
                margin-top: -11px;
                display: block;
                width: 0px;
                height: 0px;
                z-index: 9;
            }
            .microphone {
                display: flex;
                align-items: center;
                padding-bottom: 60px;
                border-radius: 10px;
                .img {
                    width: 50px;
                    height: 50px;
                    margin-right: 20px;
                    cursor: pointer;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .charts {
                    width: 260px;
                    height: 58px;
                    background: rgba(255, 255, 255, 0.6);
                    border: 1px solid #ffffff;
                    border-radius: 10px;
                    .sound_wave_canvas {
                        width: 258px;
                        height: 56px;
                        background: #e7f0fd;
                        border-radius: 10px;
                    }
                }
            }
        }
        .right {
            width: 620px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            position: relative;
            .chat_box {
                width: 620px;
                height: 450px;
                display: flex;
                flex-direction: column;
                overflow: scroll;
                overflow-x: hidden;

                .chat_item {
                    display: flex;
                    margin-bottom: 30px;
                    display: inline-block;
                    max-width: 496px;
                    padding: 19px 30px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    background: rgba(255, 255, 255, 0.6);
                    border-radius: 29px;
                    border: 1px solid #ffffff;
                }
                .user {
                    align-self: flex-end;
                    background: #2e4bf2;
                    color: #ffffff;
                }
            }
            .chat_box::-webkit-scrollbar {
                width: 0;
                height: 0;
            }

            .audio_text {
                position: absolute;
                bottom: 140px;
                left: 0;
                display: inline-block;
                max-width: 100%;
                padding: 19px 30px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
                line-height: 20px;
                background: rgba(255, 255, 255, 0.6);
                border-radius: 29px;
                border: 1px solid #ffffff;
            }

            .chat_bottom {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-bottom: 56px;
                margin-top: 8px;
                .input_box {
                    width: 500px;
                    height: 58px;
                    background: #ffffff;
                    border-radius: 29px;
                    margin-right: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    input {
                        width: 440px;
                        padding-left: 26px;
                        padding-right: 30px;
                        border-radius: 25px/50%;
                        height: 58px;
                        border: 0;
                        font-size: 14px;
                    }
                    span {
                        font-size: 14px;
                        color: #2e4bf2;
                    }
                }
                .btn {
                    width: 50px;
                    height: 50px;
                    cursor: pointer;
                    img {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}
</style>
